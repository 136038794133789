// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-index-js": () => import("./../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-contact-us-form-js": () => import("./../src/pages/contact-us/form.js" /* webpackChunkName: "component---src-pages-contact-us-form-js" */),
  "component---src-pages-contact-us-index-js": () => import("./../src/pages/contact-us/index.js" /* webpackChunkName: "component---src-pages-contact-us-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-index-js": () => import("./../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-smartix-after-sales-js": () => import("./../src/pages/smartix/after-sales.js" /* webpackChunkName: "component---src-pages-smartix-after-sales-js" */),
  "component---src-pages-smartix-architecture-js": () => import("./../src/pages/smartix/architecture.js" /* webpackChunkName: "component---src-pages-smartix-architecture-js" */),
  "component---src-pages-smartix-data-management-js": () => import("./../src/pages/smartix/data-management.js" /* webpackChunkName: "component---src-pages-smartix-data-management-js" */),
  "component---src-pages-smartix-fulfilment-js": () => import("./../src/pages/smartix/fulfilment.js" /* webpackChunkName: "component---src-pages-smartix-fulfilment-js" */),
  "component---src-pages-smartix-index-js": () => import("./../src/pages/smartix/index.js" /* webpackChunkName: "component---src-pages-smartix-index-js" */),
  "component---src-pages-smartix-journey-planner-js": () => import("./../src/pages/smartix/journey-planner.js" /* webpackChunkName: "component---src-pages-smartix-journey-planner-js" */),
  "component---src-pages-smartix-reservations-js": () => import("./../src/pages/smartix/reservations.js" /* webpackChunkName: "component---src-pages-smartix-reservations-js" */),
  "component---src-pages-smartix-settlement-js": () => import("./../src/pages/smartix/settlement.js" /* webpackChunkName: "component---src-pages-smartix-settlement-js" */),
  "component---src-pages-smartix-split-ticketing-js": () => import("./../src/pages/smartix/split-ticketing.js" /* webpackChunkName: "component---src-pages-smartix-split-ticketing-js" */),
  "component---src-pages-smartix-user-interface-js": () => import("./../src/pages/smartix/user-interface.js" /* webpackChunkName: "component---src-pages-smartix-user-interface-js" */)
}

